<template>
  <div class="samples flex flex-column gap-4">
    <div class="flex items-center justify-between gap-2 w-100">
      <div class="flex gap-8">
        <div class="flex flex-column gap-1">
          <div>State:</div>
          <button-select
            v-model="filters.sample_state"
            :options="options.sample_state"
            size="sm"
            @input="fetchSamples()"
          />
        </div>
      </div>
      <div class="flex gap-2">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="createSample()"
        >
          Add a new test
        </b-button>
      </div>
    </div>

    <div class="font-bold">
      Showing {{ samples.length | format }} / {{ sampleCount | format }} test(s)
    </div>
    <table class="table table-google table-samples">
      <thead>
        <tr>
          <th>ID</th>
          <th>State</th>
          <th class="text-nowrap">Sample Number</th>
          <th class="text-nowrap">Note</th>
          <th>Date</th>
          <th>Weight</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="8">
            <div class="font-bold">
              <span v-if="isLoading">Loading..</span>
              <span v-else-if="samples.length === 0">No samples found</span>
            </div>
          </td>
        </tr>

        <tr v-for="sample in samples" :key="sample.id">
          <td class="row-fit">{{ sample.id }}</td>
          <td>
            <div class="d-flex gap-2 text-nowrap">
              <div>
                <b-badge
                  :variant="sampleStateBadge[sample.sample_state]"
                >
                  {{ sample.sample_state | capitalize }}
                </b-badge>
              </div>

              <div
                class="d-flex gap-1"
              >
                {{ sample.sample_issue }}
              </div>
            </div>
          </td>
          <td class="row-fit text-nowrap">{{ sample.sample_number }}</td>
          <td class="row-fit text-nowrap">{{ sample.sample_note }}</td>
          <td class="row-fit">{{ sample.sample_date | isodate }}</td>
          <td class="row-fit text-right">
            <span v-if="sample.sample_weight.length > 0">{{ sample.sample_weight }} g</span>
          </td>
          <td class="row-fit">
            <div class="flex gap-2">
             <b-button
                variant="primary"
                size="sm"
                @click="editSample(sample)"
              >
                Edit
              </b-button>

              <b-button
                variant="danger"
                size="sm"
                @click="deleteSample(sample)"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination
      v-model="filters.page"
      :total-rows="sampleCount"
      :per-page="filters.per_page"
      @input="fetchSamples(true)"
      :disabled="isLoading"
    ></b-pagination>

    <sample-modal
      v-if="sampleModal.showModal"
      :data="sampleModal"
      :periods="periods"
      :issues="issues"
      @change="onSampleChange"
    />
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const SampleModal = () => import('@/components/burn_samples/SampleModal.vue');

export default {
  name: 'Samples',
  components: {
    ButtonSelect,
    SampleModal,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      samples: [],
      sampleCount: 0,
      periods: [],
      issues: [],
      filters: {
        sample_state: null,
        page: 1,
        per_page: 50,
        search: '',
      },
      options: {
        sample_state: [
          { text: 'All', value: null },
          { text: 'Pending', value: 'pending' },
          { text: 'Success', value: 'Success' },
          { text: 'Failure', value: 'failure' },
        ],
      },
      invoiceTypeBadge: {
        invoice: 'primary',
        proforma: 'light',
      },
      sampleStateBadge: {
        pending: 'warning',
        success: 'success',
        failure: 'danger',
      },
      sampleModal: {
        showModal: false,
        sample: null,
      },
    };
  },
  methods: {
    onSampleChange() {
      this.fetchSamples();
    },
    fetchPeriods() {
      this.loadingCount++;
      this.$http
        .get('/burn_sample/period')
        .then((res) => {
          this.periods = res.body.periods;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch periods: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchIssues() {
      this.loadingCount++;
      this.$http
        .get('/burn_sample/issue')
        .then((res) => {
          this.issues = res.body.issues;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch issues: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchSampleData() {
      this.loadingCount++;
      this.$http
        .get('/burn_sample/sample')
        .query(this.filters)
        .then((res) => {
          this.samples = res.body.samples;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch samples: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchSampleCount() {
      this.loadingCount++;
      this.$http
        .get('/burn_sample/sample_count')
        .query(this.filters)
        .then((res) => {
          this.sampleCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch sample count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchSamples(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchSampleCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchSampleData();
    },
    createSample() {
      this.loadingCount++;
      this.$http
        .post('/burn_sample/sample')
        .then((res) => {
          this.samples.unshift(res.body.sample);
          this.editSample(res.body.sample);
          this.sampleCount++;
        })
        .catch((err) => {
          alert(`Failed to create sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    createInvoice() {
      this.loadingCount++;
      this.$http
        .post('/invoice')
        .query(this.filters)
        .then((res) => {
          this.invoices.push(res.body.invoice);
          this.fetchInvoiceCount();
          this.editInvoice(res.body.invoice);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create invoice: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    editInvoice(invoice) {
      this.invoiceModal.invoice = invoice;
      this.invoiceModal.showModal = true;
    },
    onInvoiceChange(invoiceId) {
      const invoiceIndex = this.invoices.findIndex((invoice) => invoice.id === invoiceId);
      if (invoiceIndex === -1) {
        return;
      }
      this.loadingCount++;
      this.$http
        .get(`/invoice/${invoiceId}`)
        .then((res) => {
          this.invoices[invoiceIndex] = res.body.invoice;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch invoice: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onInvoiceDelete(invoiceId) {
      this.invoices = this.invoices.filter((invoice) => invoice.id !== invoiceId);
      this.fetchInvoiceCount();
    },
    editSample(sample) {
      this.sampleModal.sample = sample;
      this.sampleModal.showModal = true;
    },
    deleteSample(sample) {
      if (!confirm(`Do you really wish to delete '${sample.sample_number}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/burn_sample/sample/${sample.id}`)
        .then(() => {
          this.samples = this.samples.filter((item) => item.id !== sample.id);
          this.sampleCount--;
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete sample: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchPeriods();
    this.fetchIssues();
    this.fetchSamples();
  },
};
</script>

<style lang="scss" scoped>
  .samples {
    max-width: 1100px;
  }

  .table-samples .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 20px;
  }

  .search-field {
    width: 250px;
  }
</style>
